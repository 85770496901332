.delog-wrap{
  width: 1.5rem;
  height: 1.5rem;
  position: fixed;
  top: 57%;
  right: 0.3rem;
  z-index: 997;
  img{
    width: auto;
    height: 100%;
  }
}
.delog{
  background-color: #07C062;
  width: 80%;
  height: auto;
  position: fixed;
  top: 14%;
  left: 10%;
  text-align: center;
  padding-top: .3rem;
  padding-bottom: .8rem;
  border-radius: .2rem;
  z-index: 1000;
  img{
    width: 3rem;
    height: auto;
    margin-top: 0.3rem;
    margin-bottom: 0.4rem;
  }
  p{
    color: white;
    font-size: 0.3rem;
  }
  .font1{
    margin-top:0.1rem;
  }
}
.delogBg{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 998;
  opacity: 0.5;
}