@font-face {
  font-family: 'iconfont';
  src: url('//at.alicdn.com/t/font_2180207_fl656kduazh.eot?t=1604467260961'); /* IE9 */
  src: url('//at.alicdn.com/t/font_2180207_fl656kduazh.eot?t=1604467260961#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAAKcAAsAAAAABkQAAAJSAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCCcApQZAE2AiQDCAsGAAQgBYRtBy0bhwXIrrApw71IkZq4zrEReMHvbcHAKETwtV/Zb/4cIUhADahOn4oluYkDMhLKR1H5jWbPRuz9r7XZMw11Xj+Plxy1ujp/xWcHe51QPEQioRAqIUJogENOD0CxtaVw9GqvPkWSPnsLgha5T9w7/RPog7QeUI570RqDuoC6OJAC3QOjOJESyrhh7AKXcJ9AvWED2tHO0RU0ZNayQNzxXRMaOb8ss1StUF2zN4tXqtSmx0ngZfj9+G8WDZKqglVxert1YPGLnVGUKiU3PiFAQIerUGARyMRlbfxYWTCurD5eMQ72VRr8UrlvE3s1C/bXWcWNYBBK7knqym41leC2JuoEBka9hej0/q7TUe9PXpe66G2q/dnjfjzceq6cffbo6efx+f5tmaa8N+/HYQF/VnxJAQ3B3ZPJud7fSkMGP28rNGX9nKB2L47gb0h79mVdbllTVqW1w0nnWVrq1aMCu9v9jTV0nTodanWNcNYYSlGoNUlmdhFVGmyiWq1d1Fuwe7hBB1YWuQ7zngBCqy8kzT6h0OqHzOwvqnT7R7XWKKPedXSc2WA6/NYhkynkoPWIvp0myrFLKyq9IPc2MjkrD3k3xIUfwunyajG1SwnxHBuKO3cmolBxGuMOeIyiKMWM04BsGXki2Xy8clv3opGdxtByiBGTIA5keYR8tlRC+aNFq/T5C8R1K2Lilp6a+A3CCv70aGpk0oPc1SS9eu7lmcId14wQClJYKoZ2wCwSiaRQVj8rQGxixBsRzcyN7UGqr2a0vi7+umNQzzKmsGsKxQPfBAA=')
      format('woff2'),
    url('//at.alicdn.com/t/font_2180207_fl656kduazh.woff?t=1604467260961')
      format('woff'),
    url('//at.alicdn.com/t/font_2180207_fl656kduazh.ttf?t=1604467260961')
      format('truetype'),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
      url('//at.alicdn.com/t/font_2180207_fl656kduazh.svg?t=1604467260961#iconfont')
      format('svg'); /* iOS 4.1- */
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: '\e65b';
}
