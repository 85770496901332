.top_menu_container {
  width: 100vw;
  height: 0.9rem;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 999;
  .content_box {
    width: 100vw;
    height: 1.1rem;
    align-items: flex-end;
    padding-bottom: 0.52rem;
    padding-left: 0.37rem;
    padding-right: 0.37rem;
    box-sizing: border-box;
    display: flex;
    overflow-x: scroll;

    .menu_item {
      flex-shrink: 0;
      font-size: 0.3rem;
      color: #666;
      padding-right: 0.38rem;
      text-decoration: none;
      &.active_link {
        color: #35a641;
        font-size: 0.34rem;
        font-weight: 800;
      }
    }
  }
}
